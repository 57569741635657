<template>
  <div class="person-profile-layout container" v-if="person">
    <person-profile-layout-info class="layout-info"
                                :person="person">
    </person-profile-layout-info>

    <div class="content">
      <ul class="navigation">
        <li>
          <router-link :to="{ name: 'persons.profile.looks' }">
            {{ $t('persons.personProfileLayout.looks') }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'persons.profile.reviews' }">
            {{ $t('persons.personProfileLayout.reviews') }}
          </router-link>
        </li>
      </ul>

      <router-view class="profile-section" :person="person"></router-view>
    </div>
  </div>
</template>

<script>
  import profileService from '@/services/queries/profileQueries.js'
  import PersonProfileLayoutInfo from './PersonProfileLayoutInfo.vue'
  import { authenticated } from '@/services/auth.js'

  export default {
    props: {
      id: {
        required: true
      }
    },

    components: {
      PersonProfileLayoutInfo
    },

    data() {
      return {
        person: null,
      }
    },

    methods: {
      loadPerson() {
        return profileService.find(this.id).then(person => this.person = person)
      },

      ...mapActions('system', [
        'openAuthModal'
      ])
    },

    watch: {
      id: {
        handler() {
          this.loadPerson()
        },
        immediate: true
      }
    }
  }
</script>

<style lang="scss">


  .person-profile-layout {
    display: flex;
    background-color: #fff;

    .layout-info {
      width: 400px;
    }

    .content {
      flex: 1 0;
    }

    .navigation {
      display: flex;
      list-style: none;
      padding: 0 0 0 10px;
      margin: 0 0 20px 0;

      & > li > a {
        display: block;
        padding: 20px;
        font-size: 14px;

        &.active, &:hover {
          color: $primary-color;
          border-bottom: 1px solid $primary-color;
        }
      }
    }

    .profile-section {
      padding: 0 20px;
    }
  }
</style>
