export default {
  props: {
    value: {
      required: false
    },

    label: {
      required: false,
      type: String
    },

    placeholder: {
      required: false,
      type: String
    },

    textMultiline: {
      required: false,
      type: Boolean,
      default: false
    },

    linkType: {
      required: false,
      type: String
    },

    labelLeft: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  computed: {
    linkHrefValue() {
      let specialLinkTypes = {
        email: 'mailto:',
        phoneNumber: 'tel:',
        website: '//'
      }

      // for custom link types (for example, 'skype:')
      if (!specialLinkTypes[this.linkType]) {
        return this.linkType + this.value
      }

      return specialLinkTypes[this.linkType] + this.value
    }
  }
}