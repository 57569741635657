<template>
  <div class="person-profile-layout-info">
    <div class="main-info">
      <base-avatar size="big"
                   :user="person"
                   class="avatar">
      </base-avatar>
      <div class="nick-name">{{ person.nick_name }}</div>
      <!-- TODO: hidden temporarily. Uncomment this when necessary -->
      <!-- <div class="star-rating">
        <star-rating :rating="person.averageRating"
                    :star-size="25"
                    :show-rating="false"
                    :inline="true"
                    :read-only="true"
                    :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                    :rounded-corners="false"></star-rating>
      </div> -->
      <div class="indexes">
        <div class="index">
          <div class="index-value">{{ person.lookNumber }}</div>
          <div class="index-name">{{ $t('persons.personProfileInfo.looks') }}</div>
        </div>
        <div class="index">
          <div class="index-value">{{ person.followingNumber }}</div>
          <div class="index-name">{{ $t('persons.personProfileInfo.followingNumber') }}</div>
        </div>
        <div class="index">
          <div class="index-value">{{ person.followersNumber }}</div>
          <div class="index-name">{{ $t('persons.personProfileInfo.followersNumber') }}</div>
        </div>
      </div>
      <base-button class="follow-button"
                   v-if="showFollowButton"
                   :loading="loadingFollowButton"
                   @click="follow">{{ $t('persons.personProfileInfo.follow') }}</base-button>
      <template v-if="isFollowing">
        <div class="aready-following">{{ $t('persons.personProfileInfo.alreadyFollowing') }}</div>
        <a href="#"
           class="unfollow"
           @click.prevent="unfollow">{{ $t('persons.personProfileInfo.unfollow') }}</a>
      </template>
    </div>

    <div class="additional-info">
      <person-profile-layout-info-block v-if="person.country"
                                        :label="$t('persons.personProfileInfo.additionalInfo.countryLabel')">
        <div class="country-info">
          <div class="country-name">
            {{ person.country.title }}
          </div>

          <div class="country-icon"
               :title="person.country.title"
               :style="{ 'background-image': `url(${person.country.images['small'].url})` }">
          </div>
        </div>
      </person-profile-layout-info-block>
      <person-profile-layout-info-block v-if="person.description"
                                        :label="$t('persons.personProfileInfo.additionalInfo.aboutMeLabel')"
                                        :value="person.description"
                                        text-multiline>
      </person-profile-layout-info-block>
      <person-profile-layout-info-block v-if="person.education"
                                        :label="$t('persons.personProfileInfo.additionalInfo.educationLabel')"
                                        :value="person.education"
                                        text-multiline>
      </person-profile-layout-info-block>
      <person-profile-layout-info-block v-if="person.web_site"
                                        :label="$t('persons.personProfileInfo.additionalInfo.websiteLabel')"
                                        :value="person.web_site"
                                        linkType="website">
      </person-profile-layout-info-block>
    </div>
  </div>
</template>

<script>
  import hasBackgroundMixin from '@/services/mixins/hasBackgroundMixin.js'
  import StarRating from 'vue-star-rating'
  import { authenticated } from '@/services/auth.js'
  import profileService from '@/services/queries/profileQueries.js'
  import PersonProfileLayoutInfoBlock from './PersonProfileLayoutInfoBlock/PersonProfileLayoutInfoBlock.vue'

  export default {
    props: {
      person: {
        required: true,
        type: Object
      }
    },

    data() {
      return {
        loadingFollowButton: false,
        isFollowing: this.person.isFollowing
      }
    },

    components: {
      StarRating,
      PersonProfileLayoutInfoBlock
    },

    mixins: [hasBackgroundMixin],

    methods: {
      follow() {
        if (!authenticated()) {
          this.openAuthModal({ content: 'login' })
          return
        }

        this.loadingFollowButton = true
        profileService.follow(this.person.user_id).then(() => {
          this.loadingFollowButton = false
          this.isFollowing = true
        })
      },

      unfollow() {
        profileService.unfollow(this.person.user_id).then(() => {
          this.isFollowing = false
        })
      },

      ...mapActions('system', [
        'openAuthModal'
      ])
    },

    computed: {
      showFollowButton() {
        if (!this.$currentUser) {
          return true
        }

        if (this.person.user_id === this.$currentUser.user_id || this.isFollowing) {
          return false
        }

        return true
      }
    }
  }
</script>

<style lang="scss">
  .person-profile-layout-info {
    padding: 20px 40px 0;
    border-right: 1px solid #eee;

    .main-info {
      margin-bottom: 40px;

      .avatar {
        margin: 0 auto 10px;
      }

      .nick-name {
        font-weight: bold;
        font-size: 26px;
        color: #6981D3;
        margin-bottom: 10px;
        text-align: center;
      }

      .name {
        margin-bottom: 30px;
        text-align: center;
      }

      .star-rating {
        text-align: center;
      }

      .indexes {
        display: flex;
        justify-content: space-between;
        margin: 30px auto;
        width: 250px;
        text-align: center;

        .index-value {
          font-size: 16px;
          color: #1C1C1C;
          line-height: 22px;
        }

        .index-name {
          font-size: 12px;
          color: #B7B7B7;
        }
      }

      .follow-button {
        width: 100%;
      }

      .already-subscribed {
        color: #6981D3;
      }
    }

    .additional-info {
      padding-top: 25px;
      border-top: 1px solid #e6e1e1;

      .country-info {
        display: flex;
        align-items: center;

        .country-name {
          margin-right: 10px;
        }

        .country-icon {
          width: 30px;
          height: 30px;
          background: center no-repeat;
          background-size: contain;
        }
      }
    }
  }
</style>
